<template>
  <v-sheet outlined class='my-4'>
    <v-toolbar dense flat>
      <span class="text-subtitle-1" v-if="$props.title">{{ $props.title}}</span>
      <v-spacer />

      <v-btn
        icon
        color="primary"
        class="text-h5"
        v-if="$props.is_can_add"
        @click="addParameter()"
      >+</v-btn
      >
    </v-toolbar>
    <v-simple-table>
      <template>
        <thead>
        <tr>
          <th v-for=" (key,index) in  settingKeysForTitle">{{parameterSettingTitle(key)}}</th>

<!--          <th v-if="$props.setting.input_field">Input</th>-->
<!--          <th v-if="$props.setting.operator">Operation</th>-->
<!--          <th v-if="$props.setting.crm_field">-->
<!--            Matching Field-->
<!--          </th>-->
<!--          <th v-if="$props.setting.default">Default</th>-->
<!--          <th v-if="$props.setting.lookup_entity">Lookup</th>-->
          <th>Action</th>
        </tr>
        </thead>
        <tbody >
        <!-- TODO: Use param id instead of crm_field -->
        <template v-for="(param,index) in $props.parameters" v-if="!param.delete">
          <!--            <tr v-for="(param,index) in $props.parameters" v-if="!param.delete" :key="'parameter_'+ index+'_'+random_key">-->
          <tr :key="'parameter_'+ index+'_'+random_key">
            <td v-for=" (key,index) in  settingKeysForTitle">
              <input-text-field
                :filled="true"
                :dense="true"
                v-model="param[key]"
                :placeholder="$props.setting[key].description"
                v-on:blur="trimText(index,key)"
              ></input-text-field>
            </td>
<!--            <td v-if="$props.setting.input_field">-->
<!--              <input-text-field-->
<!--                :filled="true"-->
<!--                :dense="true"-->
<!--                v-model="param.input_field"-->
<!--                :placeholder="$props.setting.input_field.description"-->
<!--                v-on:blur="trimText(index,'input_field')"-->
<!--              ></input-text-field>-->
<!--            </td>-->
<!--            <td v-if="$props.setting.operator">-->
<!--              <input-text-field-->
<!--                :filled="true"-->
<!--                :dense="true"-->
<!--                v-model="param.operator"-->
<!--                :placeholder="$props.setting.operator.description"-->
<!--                v-on:blur="trimText(index,'operator')"-->
<!--              ></input-text-field>-->
<!--            </td>-->
<!--            <td v-if="$props.setting.crm_field">-->
<!--              <input-text-field-->
<!--                :filled="true"-->
<!--                :dense="true"-->
<!--                v-model="param.crm_field"-->
<!--                :placeholder="$props.setting.crm_field.description"-->
<!--                v-on:blur="trimText(index,'crm_field')"-->
<!--              ></input-text-field>-->
<!--            </td>-->
<!--            <td v-if="$props.setting.default">-->
<!--              <input-text-field-->
<!--                :filled="true"-->
<!--                :dense="true"-->
<!--                v-model="param.default"-->
<!--                :placeholder="$props.setting.default.description"-->
<!--              ></input-text-field>-->
<!--            </td>-->
<!--            <td v-if="$props.setting.lookup_entity">-->
<!--              <input-text-field-->
<!--                :filled="true"-->
<!--                :dense="true"-->
<!--                v-model="param.lookup_entity"-->
<!--                :placeholder="$props.setting.lookup_entity.description"-->
<!--                v-on:blur="trimText(index,'lookup_entity')"-->
<!--              ></input-text-field>-->
<!--            </td>-->
            <td style="display:flex">
              <v-btn
                icon
                color="red"
                v-on:click="deleteParameter(index)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                icon
                color="blue"
                v-on:click="collapseExpandParams(index)"
              >
                <v-icon v-if="param.expanded">mdi-chevron-up-circle</v-icon>
                <v-icon v-else>mdi-chevron-down-circle</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="param.expanded" :key="'parameter_mapping_option'+ index+'_'+random_key">
            <td :colspan="paramColSpan" style="padding: 0px; width: 100%">
              <div class="v-data-table theme--light" style="display: flex; justify-content:flex-end">
                <div class="v-data-table__wrapper" style="border: 1px solid; width: 80%">
                  <table style="width: 100%;">
                    <thead>
                    <tr>
                      <th style="width: calc(50% - 10px)">Input</th>
                      <th style="width: calc(50% - 10px)">Output</th>
                      <th style="width: 20px;">
                        <v-btn
                          icon
                          color="blue"
                          v-on:click="addMappingOption(index)"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(option,opt_index) in param.option_set_mapping"
                        :key="'parameter_mapping_option_row_'+index+'_'+opt_index+'_'+random_key">
                      <td>
                        <input-text-field
                          :filled="true"
                          :dense="true"
                          v-model="option.external_value"
                        ></input-text-field>
                      </td>
                      <td>
                        <input-text-field
                          :filled="true"
                          :dense="true"
                          v-model="option.crm_value"
                        ></input-text-field>
                      </td>
                      <td>
                        <v-btn
                          icon
                          color="red"
                          v-on:click="deleteMappingOption(index,opt_index)"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
  </v-sheet>
</template>

<script>
export default {
  props: {
    is_can_add: {
      default:false
    },
    parameters:{
      default() {
        return []
      }
    },
    selected_method:{
      default() {
        return {}
      }
    },setting:{
      default() {
        return {}
      }
    },
    title:{
      default:null
    },
    parameter_type:{
      default: 'matching_parameter'
    },
    step:{
      default() {
        return {}
      }
    }
  },
  data:function(){
    return {
      random_key:0
    }
  },
  methods:{
    parameterSettingTitle: function (setting) {
      switch (setting) {
        case "input_field":
          return "Input";
        case "operator":
          return "Operation";
        case "crm_field":
          return "Matching Field";
        case "default":
          return "Default";
        case "lookup_entity":
          return "Lookup";
        default:
          return setting;
      }
    },
    addParameter: function () {

      const list_item = this.setParameterItem();
      this.$props.parameters.push(list_item);
    },
    setParameterItem: function (){

      if(!this.$props.selected_method['parameter_documentation']){
        Object.assign(this.$props.selected_method,{parameter_documentation:{}})
      }

      let list_item = {};

      const parameter_setting = this.$props.setting

      if(parameter_setting){
        const parameter_setting_keys = Object.keys(parameter_setting);

        for (let i = 0; i < parameter_setting_keys.length; i++) {
          const setting = parameter_setting[parameter_setting_keys[i]];
          switch (setting) {
            case "input_field":
              Object.assign(list_item, { input_field: "" });
              break;
            case "operator":
              Object.assign(list_item, { operator: "" });
              break;
            case "crm_field":
              Object.assign(list_item, { crm_field: "" });
              break;
            case "default":
              Object.assign(list_item, { default: "" });
              break;
            case "lookup_entity":
              Object.assign(list_item, { lookup_entity: "" });
              break;
            default:
          }
        }

        if(this.default_data && this.default_data.step){
          const step = this.$props.step
          Object.assign(list_item, { idstep: step.idstep });
        }
      }

      console.log("list_item ",list_item)

      return list_item;
    },
    deleteParameter: function (index){

      if(!this.$props.parameters[index]) return;

      const parameter_type = this.parameter_type

      switch (parameter_type) {
        case "matching_parameter":
          if(this.$props.parameters[index].idmatching_parameter) {
            Object.assign(this.$props.parameters[index], {delete: true})
          }else{
            this.$props.parameters.splice(index,1)
          }
          this.random_key = Math.random(1,9999)
          break;
        case "step_parameter":
          if(this.$props.parameters[index].idstep_parameter) {
            Object.assign(this.$props.parameters[index], {delete: true})
          }else{
            this.$props.parameters.splice(index,1)
          }
          this.stp_rand = Math.random(1,9999)
          break;
        case "context_data_parameter":
          if(this.$props.parameters[index].idcontext_data_parameter) {
            Object.assign(this.$props.parameters[index], {delete: true})
          }else{
            this.$props.parameters.splice(index,1)
          }
          this.ctd_random = Math.random(1,9999)
          break;
        default:
      }
    },
    collapseExpandParams:function (index){
      this.$props.parameters[index].expanded = !this.$props.parameters[index].expanded
      this.random_key = Math.random(1,9999)
    },
    addMappingOption: function (index){
      const defaultData = {external_value:"",crm_value:""};

      if(!this.$props.parameters[index].option_set_mapping) {
        Object.assign(this.$props.parameters[index], {option_set_mapping: []})
      }
      this.$props.parameters[index].option_set_mapping.push(defaultData);
      this.random_key = Math.random(1,9999)
    },
    deleteMappingOption: function (index,option_index){

      if(this.$props.parameters[index].option_set_mapping) {
        this.$props.parameters[index].option_set_mapping.splice(option_index,1)
      }
      this.random_key = Math.random(1,9999)
    },
    trimText:function (index,key){
      if (this.$props.parameters && this.$props.parameters[index] && this.$props.parameters[index][key])
        this.$props.parameters[index][key] = this.$props.parameters[index][key].trim()
    },
  },
  computed:{
    settingKeysForTitle(){
      let keys = Object.keys(this.$props.setting)

      const invisible_keys = ['canAdd','list','option_set_mapping','option_set_mapping.','type'];

      for(let i = 0; i < invisible_keys.length;i++){
        const invisible_key_index = keys.indexOf(invisible_keys[i]);
        if(invisible_key_index >= 0)
          keys.splice(invisible_key_index,1);
      }

      return keys;
    },
    paramColSpan: function (obj){
      return Object.keys(this.setting).length;
    },
  }
}
</script>

<style scoped>

</style>
