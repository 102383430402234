<!--========================
Author by: Tith Vannarith
Created on: 05.Sept.2021
Modified:
Company: Viazul
Description:
=========================-->
<template>
  <v-dialog v-model="showModal" :width="windowWidth">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-1 mr-4" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
    </template>

    <v-card class="flow-global-setting-dialog">
      <v-card-title> Flow Global Settings </v-card-title>

      <v-card-text>
        <v-alert
          v-show="warningModalState"
          text
          :type="warningModalColor"
          transition="fade-transition"
          ><div v-html="warningMessage"></div
        ></v-alert>

        <v-container fluid>
          <v-row>
            <v-col cols="12" lg="6">
              <v-sheet>
                <v-form>
                  <label class="text-caption font-weight-bold black--text"
                    >Form</label
                  >
                  <v-select
                    class="mt-4"
                    v-model="selectedForm"
                    :items="forms"
                    item-text="name"
                    placeholder="Select Existing or Create New"
                    return-object
                    @change="getRoutingKeys(selectedForm.idform)"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <span v-if="item.idform">
                        {{item.name}}
                      </span>
                      <span v-else style="color:gray">
                        {{item.name}}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                    <span v-if="item.idform">
                        {{item.name}}
                      </span>
                      <span v-else style="color:gray">
                        {{item.name}}
                      </span>
                    </template>
                  </v-select>

                  <v-text-field
                    label="Form name"
                    v-model="selectedForm.name"
                    persistent-placeholder
                  />

                  <v-row>
                    <v-col cols="4">
                      <label class="text-caption">Open</label>
                      <v-switch
                        v-model="selectedForm.open"
                        class="mt-0"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <label class="text-caption">Readonly</label>
                      <v-switch
                        v-model="selectedForm.readonly"
                        class="mt-0"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <label class="text-caption">Active</label>
                      <v-switch
                        v-model="selectedForm.active"
                        class="mt-0"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-end mt-2">
                    <primary-button @click="createForm">
                      Save
                    </primary-button>
                  </div>

                </v-form>
              </v-sheet>

              <v-sheet class="mt-8">
                <div class="text-caption font-weight-bold black--text">
                  Routing Key
                </div>
                <div class="my-4">
                  <div
                    v-if="routing_keys.length == 0"
                    class="text-center grey--text"
                  >
                    Please add a routing key
                  </div>
                  <table v-else>
                    <thead>
                      <tr
                        class="text-caption grey--text text--darken-1 text-left"
                      >
                        <th>Key</th>
                        <th class="px-1">API Key</th>
                        <th class="pl-2">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(routingKey, index) in routing_keys"
                        :key="index"
                      >
                        <td class="pr-2">
                          <v-text-field
                            :disabled="routingKey.readonly == 1"
                            v-model="routingKey.key"
                            @change="updateRoutingKey('key', index)"
                            :vkey="routing_rand"
                            hide-details
                          >
                          </v-text-field>
                        </td>
                        <td class="px-1">
                          <v-text-field
                            v-model="routingKey.idapi_key"
                            :vkey="routing_rand"
                            hide-details
                            disabled
                          ></v-text-field>

                        </td>
                        <td class="pl-2">
                          <v-text-field
                            @change="updateRoutingKey('description', index)"
                            v-model="routingKey.description"
                            :vkey="routing_rand"
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="text-align: right">
                <primary-button @click="showCreateRoutingKeyModal = true">
                  Add
                </primary-button>
                </div>
              </v-sheet>

              <v-sheet class="mt-8">
                <div class="text-caption font-weight-bold black--text">
                  Example Input
                </div>
                <v-textarea
                  class="mt-4"
                  outlined
                ></v-textarea>
              </v-sheet>
            </v-col>

            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" style="padding-top: 0">
                  <v-expansion-panels>
                    <v-expansion-panel class="level-1">
                      <v-expansion-panel-header>Show all eligible environments</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels accordion>
                          <v-expansion-panel
                            class="level-2"
                            v-for="(environment, index) in environments" :key="index"
                          >
                            <v-expansion-panel-header>
                              <v-row>
                                <v-col
                                  cols="12"
                                  class="middle"
                                  style="padding-left: 40px"
                                >
                                  <div

                                    v-bind:class="[
                                    'wiresk-checkbox',
                                    isLinkedToForm(environment)
                                    ? 'checked'
                                    : '',
                                    ]"

                                    :vkey="environment_rand"
                                    @click.stop="
                                      linkFormToEnvironment(
                                        $event,
                                        environment,
                                        index
                                      )
                                    "
                                  >

                                    <v-icon
                                      v-if="isLinkedToForm(environment)"
                                      large
                                      color="white"
                                      >mdi-check</v-icon
                                    >
                                  </div>

                                  {{ environment.name }}
                                </v-col>
                              </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row
                                class="environment-row first"
                                style="margin: 0"
                              >
                                <v-col cols="4" class="py-3 font-weight-bold"
                                  >Connection</v-col
                                >
                                <v-col cols="4" class="py-3 font-weight-bold"
                                  >Platform</v-col
                                >
                                <v-col
                                  cols="4"
                                  class="py-3 font-weight-bold last"
                                  >Tag</v-col
                                >
                              </v-row>
                              <v-row
                                v-for="(data, index) in environment.connections" :key="index"
                                class="environment-row"
                                style="margin: 0"
                              >
                                <v-col cols="4" class="py-3">{{
                                  data.connection_name
                                }}</v-col>
                                <v-col cols="4" class="py-3">{{
                                  data.platform
                                }}</v-col>
                                <v-col cols="4" class="py-3 last">{{
                                  data.tagname
                                }}</v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <primary-button @click="onOkClick">
          Ok
        </primary-button>
      </v-card-actions>
    </v-card>

    <v-dialog max-width="400px" v-model="showCreateRoutingKeyModal">
      <NewRoutingKey
        :api_keys="api_keys"
        :idform="selectedForm ? selectedForm.idform : null"
        @cancel="showCreateRoutingKeyModal = false"
        @done="refreshRoutingKeyList"
      />
    </v-dialog>

  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate"
import {required, minLength, sameAs} from "vuelidate/lib/validators"
import NewRoutingKey from "./NewRoutingKey";
export default {
  name: "FlowGlobalSettingModal",
  computed: {

  },
  components:{
    NewRoutingKey
  },
  data: function () {
    return {
      showCreateRoutingKeyModal:false,
      warningMessage: 'Something went wrong',
      warningModalState: false,
      warningModalColor: 'error',
      selectPlaceholder: '-- Please Choose --',
      routing_rand: 0,
      environment_rand: 0,
      responses: {},
      showModal: false,
      windowWidth: 400,
      forms: [],
      selectedForm: {
        idform: null,
        name: null,
        open: null,
        active: null,
        readonly: null
      },
      defaultFormObject: {
        idform: '',
        name: '',
        open: '-- Please Choose --',
        active: '-- Please Choose --',
        readonly: '-- Please Choose --'
      },
      sel_options: [
        {label: "No", value: "0"},
        {label: "Yes", value: "1"}
      ],
      collapse: false,
      routing_keys: [],
      environments: [],
      api_keys:[],
    }
  },
  methods: {
    onOkClick: function (){
      this.showModal = false
      const idform = this.selectedForm.idform
      this.$emit('onFlowGlobalSettingSave',idform)
    },
    refreshRoutingKeyList : function (routing_key){
      this.getRoutingKey(this.selectedForm.idform, routing_key.idrouting_key);
      this.showCreateRoutingKeyModal = false
    },
    testClick: function (event){
      if(event.target.className === "wiresk-checkbox"){
        console.log(event.target.className)
        event.preventDefault()
        // event.stopImmediatePropagation()
        // event.stopPropagation()
      }
    },
    alertMessage:function(message = null,color=""){
      if(message && message != "" ){
        this.warningMessage = message
      }
      if(color && color != "" ){
        this.warningModalColor = color
      }
      this.warningModalState =true
      setTimeout(()=>{
        this.warningModalState = false
      },3000)
    },
    isLinkedToForm: function (environment){
      if(environment.forms && this.selectedForm){
        if(!this.selectedForm.idform) return false;
        for(let i =0;i < environment.forms.length;i++){
          if(this.selectedForm.idform == environment.forms[i].idform){
            console.log(this.selectedForm.idform +" == "+environment.forms[i].idform,this.selectedForm.idform == environment.forms[i].idform)
            return true;
          }
        }
      }
      return false;
    },
    addRoutingKey: function () {
      this.routing_keys.push({key: '', api_key: '', description: ''});
    },
    firstRowClass: function (index) {
      return index == 0 ? 'first' : '';
    },
    assignFormData: function () {
      this.getUserForm();
    },
    getUserForm: function () {
      if (this.forms.length > 0) {
        console.log(this.forms[0]);
        let f = this.forms[0];
        this.selectedForm = {name: f.form_name, idform: f.idform, active: f.active, readonly: f.readonly}
      }

      if (this.selectedForm.idform != "") {
        this.getRoutingKeys(this.selectedForm.idform);
      } else {
        this.$store.commit('set', ['modalSpinnerText', 'Loading...']);
        this.$store.commit('toggle', 'showHideModalSpinner');
        this.$http
          .get('/api/form/without_flows', {
            headers: {
              'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
              'Include-Form-String': 'true'
            }
          })
          .then(response => {
            if (response.status === 200) {
              this.selectedForm = response.data[0];
              this.getRoutingKeys(this.selectedForm.idform);
            }
            this.$store.commit('toggle', 'showHideModalSpinner');
          })
          .catch(function (error) {
            console.log(error);
            this.$store.commit('toggle', 'showHideModalSpinner');
          });
      }
    },
    getSelectedForm: function (index) {
      if (this.forms.length > 0 && index > 0) {
        if (typeof this.forms[index] != "undefined") {
          let f = this.forms[index];
          this.selectedForm = {name: f.name, idform: f.idform, active: f.active, readonly: f.readonly};
        }
      }

      if (this.selectedForm.idform != "") {
        this.getRoutingKeys(this.selectedForm.idform);
        this.getApiKeys();
      }
    },
    getRoutingKeys: function (idform) {
      // this.$store.commit('toggle', 'showHideModalSpinner');
      this.routing_keys = [];
      if (!idform || idform == "" || idform == null) {
        return;
      }
      this.$store.commit('set', ['modalSpinnerText', 'Loading...']);
      this.$store.commit('toggle', 'showHideModalSpinner');
      this.$http
        .get('/api/form/'+this.selectedForm.idform+'/routing_keys', {
          headers: {
            'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
            'Include-Form-String': 'true'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.routing_keys = response.data;
          }
          this.$store.commit('toggle', 'showHideModalSpinner');
        })
        .catch(function (error) {
          console.log(error);
          this.routing_keys = [];
          this.$store.commit('toggle', 'showHideModalSpinner');
        });
    },
    getRoutingKey: function (idform, idrouting_key,current_index = -1) {
      // this.$store.commit('toggle', 'showHideModalSpinner');
      if (!idform || idform == "" || idform == null) {
        return;
      }
      if (!idrouting_key || idrouting_key == "" || idrouting_key == null) {
        return;
      }
      this.$store.commit('set', ['modalSpinnerText', 'Loading...']);
      this.$store.commit('toggle', 'showHideModalSpinner');
      this.$http
        .get('/api/form/'+this.selectedForm.idform+'/routing_keys/'+idrouting_key, {
          headers: {
            'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
            'Include-Form-String': 'true'
          }
        })
        .then(response => {
          if (response.status === 200) {
            if(current_index >= 0){
              console.log(current_index,":",this.routing_keys[current_index])
              this.routing_keys[current_index] = response.data;
            }else{
              this.routing_keys.push(response.data);
            }
          }
          this.routing_rand = Math.floor(Math.random() * 1000);
          this.$store.commit('toggle', 'showHideModalSpinner');
        })
        .catch(function (error) {
          console.log(error);
          //this.routing_keys = [];
          this.$store.commit('toggle', 'showHideModalSpinner');
        });
    },
    getEnvironments: async function () {
      const requestToken = this.$cookies.get("requestToken")
      const headers = {
        Authorization: "Bearer " + requestToken,
        "include": 'connection,form',
      }

      const result = await this.$http.get('/api/environment',{headers});
      if (result.status == 200) {
        console.log("environment ", result.data);
        this.environments = result.data;
        return true;
      }
      return false;
    },
    getForms: async function (selectedIdForm) {
      const headers = {
        'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
      }
       const result = await this.$http.get('/api/form/without_flows', {headers})
        .then(response => {
          if(response.data){
            this.forms = response.data.map((f) => {
              const re = {
                name: f.name,
                idform: f.idform,
                active: f.active == null ? 0 : f.active,
                readonly: f.readonly == null ? 0 : f.readonly,
                open: f.open == null ? 0 : f.open
              };
              return re;
            });
            this.forms.forEach((form) => {
              if (form.idform == selectedIdForm) {
                this.selectedForm = form;
                return;
              }
            });
            this.forms = [].concat([{name:'Select Existing or Create New',idform:false}],this.forms)
            console.log("this.forms ",this.forms)
            return true;
          }
          return false;
        })
        .catch(function (error) {
          console.error(error);
          return false;
          //this.$store.commit('toggle', 'showHideModalSpinner');
        });

      return result;
    },
    getApiKeys: async function (){
      return await this.$http
        .get('/api/api_key', {
          headers: {
            'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.api_keys = response.data;
            return true
          }
          return false;
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
    },
    createForm: async function () {

      if(this.selectedForm.name === ""){
        this.alertMessage(`<b>Form's name</b> is required`);
        return;
      }
      if(this.selectedForm.open == this.selectPlaceholder){
        this.alertMessage(`Please select a <b>Open</b> option`);
        return;
      }
      if(this.selectedForm.active == this.selectPlaceholder){
        this.alertMessage(`Please select a <b>Active</b> option`);
        return;
      }
      if(this.selectedForm.readonly == this.selectPlaceholder){
        this.alertMessage(`Please select a <b>Readonly</b> option`);
        return;
      }

      const headers = {
        'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
      };
      const data = {
        'name': this.selectedForm.name,
        "active": this.selectedForm.active,
        "open": this.selectedForm.open,
        "readonly": this.selectedForm.readonly,
      }
      this.$store.commit('set', ['modalSpinnerText', 'Loading...']);
      this.$store.commit('toggle', 'showHideModalSpinner');
      let form = false;
      let action = "add";
      if (!this.selectedForm.idform || this.selectedForm.idform == "" || this.selectedForm.idform == null) {
        form = await this.$http.post('/api/form', data, {headers});
      } else {
        action = "update"
        form = await this.$http.put('/api/form/' + this.selectedForm.idform, data, {headers});
      }
      this.$store.commit('toggle', 'showHideModalSpinner');
      if (form && action == "add") {
        this.getForms(form.data.idform);
      }
    },
    addLiveRoutingKey: async function () {



      return;

      if(!this.selectedForm){
        this.alertMessage("Please select a form")
        return
      }

      if(!this.selectedForm.idform) {
        this.alertMessage("Please select a form")
        return
      }

      if(!this.api_keys || !this.api_keys.length){
        this.alertMessage("Api key not found")
        return
      }

      try {
        const headers = {
          'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
        };
        this.$store.commit('set', ['modalSpinnerText', 'Loading...']);
        this.$store.commit('toggle', 'showHideModalSpinner');

        let apikey = await this.$http.get('/api/api_key/'+this.api_keys[0].idapi_key, {headers});

        if (apikey != null) { // Key is exist
          const data = {
            'idform': this.selectedForm.idform,
            'idapi_key': this.api_keys[0].idapi_key,
            'key': this.api_keys[0].key,
            'description': ''
          }
          let routing_key = await this.$http.post('/api/routing_key', data, {headers});
          if (routing_key != null) {
            //console.log(routing_key.data);return;
            this.getRoutingKey(this.selectedForm.idform, routing_key.data.idrouting_key);
          }
          this.$store.commit('toggle', 'showHideModalSpinner');
        }else{
          this.alertMessage("Api key not found")
        }
      } catch (e) {
        console.error(e)
      }
    },
    linkFormToEnvironment: async function (event, environment, index) {

      event.preventDefault();

      if(!this.selectedForm.idform || this.selectedForm.idform === ""){
        this.alertMessage("Please select or create a form and link again")
        return;
      }

      try {
        const headers = {
          'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
        };
        let params = {
          'idenvironment': environment.idenvironment,
          'idform': this.selectedForm.idform,
          'idtag': '',
        }

        if (environment.data && environment.data.length) {
          params.idtag = environment.data[0].idtag;
        }

        if(!this.isLinkedToForm(environment)) {
          const linked = await this.$http.post('/api/environment/form_environment', params, {headers}).then(()=>{
            return true
          }).catch((error)=>{
            if(error.response){
              this.alertMessage(error.response.data)
            }
            return false;
          });
          if(linked) environment.forms.push(this.selectedForm);
        } else {
          const unlinked = await this.$http.delete('/api/environment/form_environment', {headers, params}).then(()=>{
            return true
          }).catch((error)=>{
            if(error.response){
              this.alertMessage(error.response.data)
            }
            return false;
          });
          if(environment.forms.length && unlinked) {
            for(let i=0; i < environment.forms.length; i++){
              if(environment.forms[i].idform == this.selectedForm.idform){
                environment.forms.splice(i,1);
                break;
              }
            }
          }
        }
        this.environment_rand = Math.floor(Math.random() * 1000);
      } catch (error) {
        console.log("Error ",error)
      }
    },
    updateRoutingKey: async function (property_name,index){

      const routing_key = this.routing_keys[index];

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      };
      let data = {
        'description':routing_key.description,
        'key':routing_key.key,
        'readonly':1,
      }
      if(property_name == "description" && routing_key.readonly == 0){
        data.readonly = 0;
      }
      await this.$http.patch('/api/form/'+this.selectedForm.idform+'/routing_keys/'+routing_key.idrouting_key, data, {headers});
      this.getRoutingKey(this.selectedForm.idform,routing_key.idrouting_key,index);
      //this.routing_keys[index].readonly = 1;
    },
    myDebug: function(e){
      console.log(this.selectedForm)
    }
  },
  mounted: async function () {
    // this.selectedForm.readonly = this.selectPlaceholder;
    // this.selectedForm.active = this.selectPlaceholder;
    // this.selectedForm.open = this.selectPlaceholder;
    this.windowWidth = window.innerWidth / 1.5
    // window.addEventListener('resize', () => {
    //   this.windowWidth = window.innerWidth
    // })

    await this.getForms();
    await this.getEnvironments();
    await this.getApiKeys();
  },
  mixins: [validationMixin],
  validations: {
    selectedForm: {
      name: {
        required
      },
      open: {
        required
      },
      active: {
        required
      },
      readonly: {
        required
      },
    }
  },
}
</script>

