<template>
  <v-card>

    <title-bar
      @close="$emit('close')"
    >
      <v-card-title>Step Setting</v-card-title>
    </title-bar>

    <v-card-text>
      <v-alert
        v-show="warningState"
        text
        :type="warningColor"
        transition="fade-transition"
        >{{ warningMessage }}</v-alert
      >

      <v-row class="black--text">
        <v-col cols="12" lg="3">
          <span class="text-caption">Method</span>
          <v-select
            v-model="selectedMethodKey"
            :items="methodOptions"
            placeholder="Select Method"
            outlined
            dense
            flat
            hide-details
            item-text="label"
            item-value="value"
            v-on:change="getSelectedMethod"
          />
        </v-col>
        <v-col cols="12" lg="5">
          <span class="text-caption">Connection Tag</span>
          <v-select
            outlined
            dense
            flat
            hide-details
            v-model="internalSelectedTag"
            :items="$props.availableTags"
          >
            <template v-slot:selection="{ item }">
                <span>
                  {{$props.default_data.listener ? item[$props.default_data.listener.idplatform].name : " -- "}}
                </span>
            </template>
            <template v-slot:item="{ item }">
                <span>
                  {{$props.default_data.listener ? item[$props.default_data.listener.idplatform].name : " -- "}}
                </span>
            </template>
          </v-select>


        </v-col>
        <v-col cols="12" lg="4">
          <input-text-field
            :filled="true"
            label="Entity"
            v-model="txtEntity"
            :placeholder="entityPlaceholder"
          ></input-text-field>
        </v-col>
      </v-row>

      <ParameterTable
        :parameters.async="matchingParameters"
        v-if="isMatchingParameter"
        :setting="matchingParameterSetting"
        :selected_method="selectedMethod"
        :is_can_add="canAddMatchingParameter"
        title="Matching Parameter"
        :step="default_data.step"
      />

      <ParameterTable
        :parameters.async="stepParameters"
        v-if="isStepParameter"
        :setting="stepParameterSetting"
        :selected_method="selectedMethod"
        :is_can_add="canAddStepParameter"
        title="Step Parameter"
        :step="default_data.step"
      />

      <ParameterTable
        :parameters.async="contextDataParameters"
        v-if="isContextParameter"
        :setting="contextDataParameterSetting"
        :selected_method="selectedMethod"
        :is_can_add="canAddContextDataParameter"
        title="Context Data Parameter"
        :step="default_data.step"
      />

    </v-card-text>

    <v-card-actions>
      <primary-button @click="okClick">
        OK
      </primary-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import ParameterTable from "@/components/Flow/ParameterTable"
export default {
  components:{
    ParameterTable
  },
  props: {
    methodKey: {
      default: false,
    },
    selectedTag: {
      default: [],
    },
    currentEntity:{
      default:""
    },
    default_data: {
      default: false,
    },
    availableTags: {
      type: Array,
      default: [],
    },
  },
  data: function () {
    return {
      mtp_random:0,
      stp_rand:0,
      ctd_random:0,
      step:{},
      listener:{},
      methods: {},
      methodOptions: [],
      selectedMethodKey: -1,
      warningMessage: "Something went wrong",
      warningColor: "error",
      warningState: false,
      selectedMethod: false,
      internalSelectedTag: [],
      txtEntity:"",
      entityPlaceholder:"",
      //
      isMatchingParameter: false,
      canAddMatchingParameter: true,
      matchingParameterSetting: null,
      matchingParameters: [],
      //
      isStepParameter: false,
      canAddStepParameter: true,
      stepParameterSetting: null,
      stepParameters: [],
      //
      isContextParameter: false,
      canAddContextDataParameter: true,
      contextDataParameterSetting: null,
      contextDataParameters: [],
    };
  },
  methods: {
    showMessage: function (message = null, color = "") {
      if (message && message != "" && message != null) {
        this.warningMessage = message;
      }
      if (color && color != "") {
        this.warningColor = color;
      }
      this.warningState = true;
      setTimeout(()=>{
        this.warningState = false
      },3000)
    },
    okClick: function (ev) {
      if (
        !this.internalSelectedTag
        ||
        !this.internalSelectedTag.length
        ||
        !this.txtEntity
        ||
        this.txtEntity === ""
      ) {
        this.showMessage(
          `'Tag' and 'Entity' are requirement`
        );
        return;
      }

      const listener = this.listener

      let def_step = this.default_data.step || {};

      let step  =Object.assign({},def_step, {
        edited:true,
        node_id: "",
        step_number: "",
        entity: this.txtEntity,
        idlistener: listener.idlistener,
        idplatform: listener.idplatform,
        idtag: this.internalSelectedTag,
        step_type: listener.queuename,
        step_method: this.selectedMethodKey,
        branch: 0,
        failure_behavior: "stop",
      });

      let parameter_keys = Object.keys(
        this.selectedMethod.parameter_documentation
      );

      let stepParameters = this.stepParameters.filter((param)=>{
        return Object.keys(param).length > 0
      });
      // if(typeof stepParameters == 'object'){
      //   stepParameters = [stepParameters];
      // }

      let matchingParameters = this.matchingParameters.filter((param)=>{
        return Object.keys(param).length > 0
      })
      // if(typeof matchingParameters == 'object'){
      //   matchingParameters = [matchingParameters];
      // }

      let contextDataParameters = this.contextDataParameters.filter((param)=>{
        return Object.keys(param).length > 0
      })
      // if(typeof contextDataParameters == 'object'){
      //   contextDataParameters = [contextDataParameters];
      // }


      for (let i = 0; i < parameter_keys.length; i++) {
        const parameter_key = parameter_keys[i];
        let parameter_value = false

        switch (parameter_key){
          case "step_parameter":
            parameter_value = stepParameters;
            break;
          case "matching_parameter":
            parameter_value = matchingParameters;
            break;
          case "context_data_parameter":
            parameter_value = contextDataParameters
            break;
          default:
            continue;
        }
        step[parameter_key] = parameter_value;
      }
      this.txtEntity = "";

      //console.log(step);return;

      this.$emit("ok", step, listener);
    },
    getSelectedMethod: function (value) {
      this.selectedMethodKey = value;
      this.selectedMethod = this.methods[this.selectedMethodKey];
      this.defineAllParameters(this.step);
    },
    defineAllParameters: function (step = {}) {

      this.matchingParameterSetting =
        this.getParameterSettingsFromListenerMethod("matching_parameter");

      this.isMatchingParameter =
        this.matchingParameterSetting && this.matchingParameterSetting != null;

      if (this.isMatchingParameter) {
        this.matchingParameters = this.matchingParameterSetting.list || [];
        this.canAddMatchingParameter = this.matchingParameterSetting.canAdd;

        if(step['matching_parameter']){
          this.matchingParameters = step['matching_parameter'];
        }
      }

      this.stepParameterSetting =
        this.getParameterSettingsFromListenerMethod("step_parameter");
      this.isStepParameter =
        this.stepParameterSetting && this.stepParameterSetting != null;
      if (this.isStepParameter) {
        this.stepParameters = this.stepParameterSetting.list || [];
        this.canAddStepParameter = this.stepParameterSetting.canAdd;
        if(step['step_parameter']){
          this.stepParameters = step['step_parameter'];
        }
      }

      //console.log("this.stepParameterSetting ",this.stepParameterSetting);
      //console.log("this.stepParameters ",this.stepParameters);
      //console.log("__________________________________");

      this.contextDataParameterSetting =
        this.getParameterSettingsFromListenerMethod("context_data_parameter",step);
      this.isContextParameter =
        this.contextDataParameterSetting &&
        this.contextDataParameterSetting != null;
      if (this.isContextParameter) {
        this.contextDataParameters =
          this.contextDataParameterSetting.list || [];
        this.canAddContextDataParameter =
          this.contextDataParameterSetting.canAdd;
        if(step['context_data_parameter']){
          this.contextDataParameters = step['context_data_parameter'];
        }
      }
    },
    getParameterSettingsFromListenerMethod: function (matching_parameter = "matching_parameter") {
      if (!this.selectedMethod) {
        this.selectedMethod = {};
      }

      if (!this.selectedMethod.parameter_documentation) {
        Object.assign(this.selectedMethod, { parameter_documentation: {} });
      }

      if (
        typeof this.selectedMethod.parameter_documentation[
          matching_parameter
        ] === "undefined"
      ) {
        //Object.assign(this.selectedMethod.parameter_documentation,JSON.parse("{\""+matching_parameter+"\":[]}"));
        Object.assign(
          this.selectedMethod.parameter_documentation,
          JSON.parse('{"' + matching_parameter + '":null}')
        );
      }

      if (
        this.selectedMethod.parameter_documentation[matching_parameter] != null
      ) {
        if (
          typeof this.selectedMethod.parameter_documentation[matching_parameter]
            .list === "undefined"
        ) {
          Object.assign(
            this.selectedMethod.parameter_documentation,
            JSON.parse('{"list":[]}')
          );
        }

        if (
          this.selectedMethod.parameter_documentation[matching_parameter]
            .list == null
        ) {
          this.selectedMethod.parameter_documentation[matching_parameter].list =
            [];
        }
      }
      return this.selectedMethod.parameter_documentation[matching_parameter];
    },
    setParameterItem: function (parameter_type){
      let list_item = {};

      const parameter_setting =
        this.selectedMethod.parameter_documentation[parameter_type];
      const parameter_setting_keys = Object.keys(parameter_setting);

      for (let i = 0; i < parameter_setting_keys.length; i++) {
        const setting = parameter_setting[parameter_setting_keys[i]];
        switch (setting) {
          case "input_field":
            Object.assign(list_item, { input_field: "" });
            break;
          case "operator":
            Object.assign(list_item, { operator: "" });
            break;
          case "crm_field":
            Object.assign(list_item, { crm_field: "" });
            break;
          case "default":
            Object.assign(list_item, { default: "" });
            break;
          case "lookup_entity":
            Object.assign(list_item, { lookup_entity: "" });
            break;
          default:
        }
      }

      if(this.default_data.step){
        const step = this.default_data.step
        Object.assign(list_item, { idstep: step.idstep });
      }

      return list_item;
    },
    addStepParameter: function (){
      if(this.canAddStepParameter && this.selectedMethod){
        const parameter_type = "step_parameter";
        const list_item = this.setParameterItem(parameter_type);
        this.stepParameters.push(list_item);
      }
    },
    addMatchingParameter: function (){
      if(this.canAddMatchingParameter && this.selectedMethod){
        const parameter_type = "matching_parameter";
        const list_item = this.setParameterItem(parameter_type);
        this.matchingParameters.push(list_item);
      }
    },
    addContextParameter:function (){
      if(this.canAddContextDataParameter && this.selectedMethod){
        const parameter_type = "context_data_parameter";
        const list_item = this.setParameterItem(parameter_type);
        this.contextDataParameters.push(list_item);
      }
    },
    addParameter: function (parameter_type = "matching_parameter") {

      switch (parameter_type) {
        case "matching_parameter":
          this.addMatchingParameter();
          break;
        case "step_parameter":
          this.addStepParameter();
          break;
        case "context_data_parameter":
          this.addContextParameter();
          break;
        default:
      }
    },
    deleteParameter: function (index, matching_parameter = "matching_parameter"){
      switch (matching_parameter) {
        case "matching_parameter":
          if(this.matchingParameters[index].idmatching_parameter) {
            Object.assign(this.matchingParameters[index], {delete: true})
          }else{
            this.matchingParameters.splice(index,1)
          }
          this.mtp_random = Math.random(1,9999)
          break;
        case "step_parameter":
          if(this.stepParameters[index].idstep_parameter) {
            Object.assign(this.stepParameters[index], {delete: true})
          }else{
            this.stepParameters.splice(index,1)
          }
          this.stp_rand = Math.random(1,9999)
          break;
        case "context_data_parameter":
          if(this.contextDataParameters[index].idcontext_data_parameter) {
            Object.assign(this.contextDataParameters[index], {delete: true})
          }else{
            this.contextDataParameters.splice(index,1)
          }
          this.ctd_random = Math.random(1,9999)
          break;
        default:
      }
    },
    setupSetting: function (nVal){
      //if (nVal) { // nVal is Listener

        const listener = nVal.listener
        const step = nVal.step

        console.log("setupSetting::nVal ",nVal)

        if(!listener || !step){
          console.error(nVal)
          return;
        }

        this.step = step;
        this.listener = listener;

        //console.log(listener)

        // Setup defaults
        //////////////////
        //this.txtEntity = nVal.entity;
        this.entityPlaceholder = listener.entity;
        if(typeof listener.methods == "string"){
          listener.methods = JSON.parse(listener.methods)
        }
        this.methods = listener.methods

        this.methodOptions = [];

        if(typeof listener.methods != "undefined" && listener.methods) {
          const method_keys = Object.keys(listener.methods)
          method_keys.forEach((k) => {
            const item = {value: k, label: this.methods[k].displayname};
            //console.log("item ",item)
            this.methodOptions.push(item);
          });
        }

        if(typeof this.methods === "undefined" || this.methods === null) {
          return;
        }

        if(step.step_method && step.step_method != "" ){
          this.selectedMethodKey = step.step_method;
        }else if (this.$props.methodKey && this.$props.methodKey !== "0") {
          this.selectedMethodKey = this.$props.methodKey;
        } else {
          this.selectedMethodKey = Object.keys(this.methods)[0];
        }

        // if (this.$props.methodKey && this.$props.methodKey !== "0") {
        //     this.selectedMethodKey = this.$props.methodKey;
        // } else {
        //   this.selectedMethodKey = Object.keys(this.methods)[0];
        // }

        this.selectedMethod = this.methods[this.selectedMethodKey];

        this.defineAllParameters(this.step);
      }
    //}
  },
  beforeMount: function () {},
  mounted: function () {
    console.log("availableTags ",this.$props.availableTags)
    this.internalSelectedTag = this.$props.selectedTag
    this.txtEntity = this.$props.currentEntity
    this.setupSetting(this.$props.default_data);

  },
  watch: {
    default_data: function (nVal, oVal) {
      console.log("watch:default_data",nVal)
      this.setupSetting(nVal)
    },
    currentEntity: function (nVal){
      this.txtEntity = nVal
    },
    selectedTag: function (nVal){
      if(nVal && nVal.length) {
        this.internalSelectedTag = nVal
        console.log("this.internalSelectedTag ",this.internalSelectedTag)
      }
    }
  },
};
</script>

<style scoped>
</style>
