<template>
  <v-card>
    <v-card-title>New Routing-key</v-card-title>

    <v-card-text>
      <v-form>
        <v-select
          label="Api keys"
          persistent-placeholder
          v-model="$v.routingkey.idapi_key.$model"
          :items="$props.api_keys"
          item-value="idapi_key"
          item-text="key"
        ></v-select>

        <v-text-field
          label="Description"
          persistent-placeholder
          v-model="$v.routingkey.description.$model"
          :error-messages="errorMessage('description')"
        >
        </v-text-field>

      </v-form>
    </v-card-text>
    <v-card-actions>
      <primary-button @click="createRoutingKey">Create</primary-button>
      <dismiss-button @click="$emit('cancel')">Cancel</dismiss-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {

  data () {
    return {
      platforms: null,
      routingkey: {
        idapi_key: 0,
        description: null,
      },
      // wufooConnection: null,
    };
  },
  props:{
    idform:{
      default: null
    },
    api_keys:{
      default:[]
    }
  },

  mixins: [validationMixin],
  validations: {
    routingkey: {
      idapi_key: { required },
      description: { required },
    },
  },

  mounted() {
  },

  methods: {
    async createRoutingKey() {

      if (this.$v.routingkey.$invalid) {
        this.$v.routingkey.$touch()
        return
      }

      //await this.$helpers.refreshAccessToken();
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      let data = this.prepareData()
      try {
        let routing_key = await this.$http
          .post(`/api/form/${this.idform}/routing_keys`, data, { headers })
          .then((response)=>{
            return response.data;
          }).catch(()=>{
            return false;
          })
        this.$emit('done',routing_key)
      } catch (error) {
        console.log(error)
      }
    },
    prepareData() {
      let data = {
        idapi_key: this.routingkey.idapi_key,
        description: this.routingkey.description
      }
      return data
    },
    errorMessage(fieldName) {
      return this.$v.routingkey[fieldName].$dirty &&
        !this.$v.routingkey[fieldName].required
        ? "Required"
        : null;
    }
  },
};
</script>
