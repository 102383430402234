<!--========================
Author by: Tith Vannarith
Created on: 05.Sept.2021
Modified:
Company: Viazul
Description:
=========================-->
<template>
  <div class="flow-item" v-html="$props.html">
  </div>
</template>

<script>
export default {
  name: "FlowItem",
  props:{
    html:{
      default:"Added"
    }
  },
  methods:{
  }
}
</script>

