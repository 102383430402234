<!--========================
Author by: Tith Vannarith
Created on: 05.Sept.2021
Modified:
Company: Viazul
Description:
=========================-->
<template>
  <v-toolbar flat>
    <FlowGlobalSettingModal
      v-if="!$props.hideGlobalSetting"
      @onFlowGlobalSettingSave="onFlowGlobalSettingSave"
    />
    <v-select
      outlined
      dense
      flat
      placeholder="Select form"
      hide-details
      :disabled="disableSelectForm"
      v-model="selectedForm"
      :items="forms"
      item-text="name"
      return-object
    ></v-select>

    <v-spacer></v-spacer>
    <v-text-field
      dense
      solo-inverted
      flat
      placeholder="Search step"
      hide-details
      disabled
    ></v-text-field>
  </v-toolbar>
</template>

<script>
import FlowGlobalSettingModal from "@/components/Flow/FlowGlobalSettingModal";
export default {
  name: "FlowHeader",
  props:{
    hideGlobalSetting:{
      default:false
    },
    forms:{
      default:{},
    },
    initForm:{
      default() {
        return {}
      },
      required:false
    },
    disableSelectForm:{
      default: false,
    }
  },
  data:function(){
    return {
      selectedForm:{}
    }
  },
  methods: {
    onFlowGlobalSettingSave: function(idform = null){
      this.$emit('onFlowGlobalSettingSave',idform)
    },
  },
  watch:{
    initForm:function(newVal, oldVal) {
      // if(typeof newVal != "object"){
      //   this.selectedForm = {}
      //   return;
      // }
      //if(!Object.is(newVal,oldVal)){
        this.selectedForm = newVal
      //}
    },
    selectedForm:function(val, oldVal) {
      this.$emit("selectFormChanged",val)
    },
    disableSelectForm:function(val, oldVal) {
      //console.log("disableSelectForm " ,val)
    },
  },
  components:{
    FlowGlobalSettingModal
  },
}
</script>

